var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page__privacy-policy" },
    [
      _vm.file
        ? _c(
            "pdf-viewer",
            _vm._b(
              { staticClass: "border h-100" },
              "pdf-viewer",
              { file: _vm.file },
              false
            )
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }