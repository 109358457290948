<template>
  <div class="page__privacy-policy">
    <pdf-viewer
      v-if="file"
      v-bind="{ file }"
      class="border h-100"
    />
  </div>
</template>
<script>
import PdfViewer from '@itccompliance/compliance-pdf-viewer';

export default {
  components: { PdfViewer },
  data: () => ({
    file: null,
  }),
  async mounted() {
    const location = await import('@/assets/pdf/GDPR-privacy-statement-V1.pdf');
    const response = await fetch(location.default);
    const data = await response.blob();
    this.file = new File([data], 'GDPR-privacy-statement-V1.pdf', { type: 'application/pdf' });
  },
};
</script>
